import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/user.png";
import { useAppSelector } from "../../../store/stateHooks";
import lang from "../../../utils/language/french"
function AdminProfile() {
  const SERVER_URL = process.env.REACT_APP_API_SERVER;
  const { auth } = useAppSelector((state) => state.authCredential.auth);

  const [adminName, setAdminName] = useState("");
  const [name, setName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminPhone, setAdminPhone] = useState("");
  const [adminLogo, setAdminLogo] = useState("");
  const [adminRole, setAdminRole] = useState("");
  const [checkStatus, setCheckStatus] = useState(0);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth.data.token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${SERVER_URL}/api/v1/admin/get/${auth.data.admin_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setAdminName(result.data.name);
        setName(result.data.name);
        setAdminEmail(result.data.email);
        setAdminPhone(result.data.phone);
        setAdminLogo(result.data.image);
        setAdminRole(result.data.role);
      })
      .catch((error) => console.log("error", error));
  }, [checkStatus]);

  //Trigger and upload image
  const uploadedImage = useRef();
  const [uploadImg, setUploadImg] = useState(null);
  function uploadImage() {
    uploadedImage.current.click();
  }
  // Preview and set image
  const [uploadedImg, setUploadedImg] = useState(null);
  const handleImageData = (e) => {
    setUploadedImg(e.target.files[0]);
    setUploadImg(URL.createObjectURL(e.target.files[0]));
  };
  // ===================|Update Profile|==================
  const updateData = async (e) => {
    e.preventDefault();
    setCheckStatus(0);
    document.getElementById(
      "saveChanges"
    ).innerHTML = `<div style="height:22px;width:22px" class="spinner-border text-light"></div>`;

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${auth.data.token}`);

    const formData = new FormData();
    formData.append("name", adminName);
    formData.append("email", adminEmail);
    formData.append("phone", adminPhone);
    formData.append("image", uploadedImg);

    const resMsg = document.getElementById("profile_message");
    resMsg.innerHTML = "";

    var BASE_URL = SERVER_URL + `/api/v1/admin/update/${auth.data.admin_id}`;
    let resData = await fetch(BASE_URL, {
      method: "PUT",
      body: formData,
      headers: myHeaders,
    }).then((res) => {
      return res.json();
    });

    document.getElementById("saveChanges").innerHTML = `Save Changes`;
    if (resData.status === 200) {
      setCheckStatus(1);
      resMsg.innerHTML = `<div class="alert alert-success alert-dismissible">
    <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
    <strong>${lang.common.success}!</strong>  ${resData.message}</div>`;
    } else {
      resMsg.innerHTML = `<div class="alert alert-danger alert-dismissible">
    <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
    <strong>${lang.common.error}!</strong> ${resData.message}</div>`;
    }
  };
  // ==========================|Change Password|=====================
  const changePasswordHandler = async (e) => {
    e.preventDefault();
    document.getElementById(
      "changePasswordBtn"
    ).innerHTML = `<div style="height:22px;width:22px" class="spinner-border text-light"></div>`;
    const resMsg = document.getElementById("message");
    resMsg.innerHTML = "";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${auth.data.token}`);

    var raw = JSON.stringify({
      password: e.target.password.value,
      new_password: e.target.new_password.value,
      confirm_password: e.target.confirm_password.value,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${SERVER_URL}/api/v1/admin/reset-admin-password/${auth.data.admin_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        document.getElementById("changePasswordBtn").innerHTML =
          lang.admin.changePassword;
        if (result.status === 200) {
          e.target.reset();
          resMsg.innerHTML = `<div class="alert alert-success alert-dismissible">
          <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
          <strong>${lang.common.success}!</strong> Password has been changed successfully</div>`;
        } else {
          resMsg.innerHTML = `<div class="alert alert-danger alert-dismissible">
          <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
          <strong>${lang.common.error}!</strong> ${result.message}</div>`;
        }
      })
      .catch((error) => {
        document.getElementById("changePasswordBtn").innerHTML =
          lang.admin.changePassword;
      });
  };
  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>{lang.manageAdmin.addNewAdmin}</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard">{lang.common.home}</Link>
            </li>
            <li className="breadcrumb-item active">{lang.manageAdmin.addNewAdmin}</li>
          </ol>
        </nav>
      </div>
      <section className="section dashboard">
        <section className="section profile">
          <div className="row">
            <div className="col-xl-4">
              <div className="card">
                <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                  <img
                    src={
                      adminLogo == null
                        ? logo
                        : SERVER_URL + "/profile/" + adminLogo
                    }
                    alt="Profile"
                    className="rounded-circle"
                  />
                  <h3 className="mb-2 mt-2">{name} </h3>
                  <h6>
                    {adminRole === "super-admin" ? (
                      <span className="badge rounded-pill bg-success">
                        {`Super ${lang.admin.admin}`}
                      </span>
                    ) : adminRole === "admin" ? (
                      <span className="badge rounded-pill bg-primary">
                        {lang.admin.admin}
                      </span>
                    ) : (
                      <span className="badge rounded-pill bg-info text-dark">
                        {lang.admin.subAdmin}
                      </span>
                    )}
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-xl-8 adminProfile">
              <div className="card">
                <div className="card-body pt-3">
                  <ul className="nav nav-tabs nav-tabs-bordered" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-edit"
                        aria-selected="false"
                        tabindex="-1"
                        role="tab"
                      >
                        {lang.profile.editProfile}
                      </button>
                    </li>

                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        data-bs-toggle="tab"
                        data-bs-target="#profile-change-password"
                        aria-selected="false"
                        tabindex="-1"
                        role="tab"
                      >
                        {lang.admin.changePassword}
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content pt-2">
                    <div
                      className="tab-pane fade active show profile-edit pt-3"
                      id="profile-edit"
                      role="tabpanel"
                    >
                      <div id="profile_message"></div>
                      <form
                        autoComplete="off"
                        encType="multipat/form-data"
                        onSubmit={updateData}
                      >
                        <div className="row mb-3">
                          <label
                            for="profileImage"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            {lang.profile.profileImage}
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <img
                              src={
                                uploadImg == null
                                  ? adminLogo == null
                                    ? logo
                                    : SERVER_URL + "/profile/" + adminLogo
                                  : uploadImg
                              }
                              alt="Profile"
                            />
                            <div className="pt-2">
                              <input
                                type="file"
                                id="image"
                                ref={uploadedImage}
                                name="image"
                                onChange={handleImageData}
                                accept=".jpg, .jpeg, .png"
                                className="d-none"
                              />
                              <button
                                type="button"
                                onClick={() => uploadImage()}
                                className="btn btn-primary btn-sm m-1"
                                title="Upload new profile image"
                              >
                                <i className="bi bi-upload"></i>
                              </button>
                              <button
                                type="button"
                                onClick={() => setUploadImg(null)}
                                className="btn btn-danger btn-sm m-1"
                                title="Remove my profile image"
                              >
                                <i className="bi bi-trash"></i>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label
                            for="fullName"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            {lang.common.fullName}
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="fullName"
                              type="text"
                              defaultValue={adminName}
                              onChange={(e) => setAdminName(e.target.value)}
                              className="form-control"
                              id="fullName"
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <label
                            for="Phone"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            {lang.common.phone}
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="phone"
                              type="text"
                              className="form-control"
                              onChange={(e) => setAdminPhone(e.target.value)}
                              id="Phone"
                              defaultValue={adminPhone}
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label
                            for="Email"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            {lang.common.email}
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="email"
                              type="email"
                              defaultValue={adminEmail}
                              onChange={(e) => setAdminEmail(e.target.value)}
                              className="form-control"
                              id="Email"
                            />
                          </div>
                        </div>

                        <div className="text-center">
                          <button
                            type="submit"
                            id="saveChanges"
                            className="btn btn-primary"
                          >
                            {lang.common.saveChanges}
                          </button>
                        </div>
                      </form>
                    </div>
                    <div
                      className="tab-pane fade pt-3"
                      id="profile-change-password"
                      role="tabpanel"
                    >
                      <div id="message"></div>
                      <form onSubmit={changePasswordHandler}>
                        <div className="row mb-3">
                          <label
                            for="currentPassword"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            {lang.admin.currentPassword}
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="password"
                              type="password"
                              placeholder={lang.admin.currentPasswordPlaceholder}
                              className="form-control"
                              id="currentPassword"
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label
                            for="newPassword"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            {lang.admin.newPassword}
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="new_password"
                              type="password"
                              className="form-control"
                              id="newPassword"
                              placeholder={lang.admin.newPasswordPlaceholder}
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <label
                            for="renewPassword"
                            className="col-md-4 col-lg-3 col-form-label"
                          >
                            {lang.admin.confirmPassword}
                          </label>
                          <div className="col-md-8 col-lg-9">
                            <input
                              name="confirm_password"
                              type="password"
                              className="form-control"
                              placeholder={lang.admin.confirmPasswordPlaceholder}
                              id="renewPassword"
                            />
                          </div>
                        </div>

                        <div className="text-center">
                          <button
                            type="submit"
                            id="changePasswordBtn"
                            className="btn btn-primary"
                          >
                            {lang.admin.changePassword}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </main>
  );
}

export default AdminProfile;
