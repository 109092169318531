import { useState, useRef, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import "moment/locale/fr";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import lang from "../../../utils/language/french";
import { ActivityModal } from "../../modals";
const Offers = ({ tbody }) => {
  const gridRef = useRef();
  const [paginationRow, setPaginationRow] = useState(10);
  const sortingOrder = useMemo(() => {
    return ["desc", "asc", null];
  }, []);

  const dateFormat = (params) => {
    const dateTimes = new Date(params.value);

    // Correct format for the year (yyyy instead of YYYY)
    const formattedEndDateTime = format(dateTimes, "dd MMM yyyy hh:mm:ss a", {
      locale: fr,
    });
    return formattedEndDateTime;
  };

  const shortDateFormat = (params) => {
    const dateTimes = new Date(params.value);

    // Correct format for the year (yyyy instead of YYYY)
    const formattedEndDateTime = format(dateTimes, "dd MMM yyyy", {
      locale: fr,
    });
    return formattedEndDateTime;
  };
  const [showModal, setShowModal] = useState(null);

  const handleOpenModal = (data) => {
    setShowModal(data);
  };
  const handleCloseModal = () => setShowModal(null);

  const checkStatus = (params) => {
    const status = 1;
    return (
      <button
        className="badge rounded-pill bg-success"
        onClick={() => handleOpenModal(params.data)}
      >
        {params?.data?.activity?.title ? params.data.activity.title : "To do"}
      </button>
    );
  };

  const callEmailStatus = (params) => {
    return params.value ? (
      <span className="badge rounded-pill bg-success">Activer</span>
    ) : (
      <span className="badge rounded-pill bg-danger">Désactiver</span>
    );
  };
  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Numéro de police",
      valueGetter: "data.subscriber._id",
    },
    {
      headerName: lang.common.firstName,
      // field: "subscriber",
      maxWidth: 150,
      valueGetter: "data.subscriber.firstName",
      // cellRenderer: (params) => params.value.phoneNumber,
    },
    {
      maxWidth: 150,
      headerName: lang.common.lastName,
      valueGetter: "data.subscriber.lastName",
    },
    {
      headerName: lang.admin.offerHeader.email,
      valueGetter: "data.subscriber.email",
    },
    {
      headerName: lang.admin.offerHeader.phoneNumber,
      valueGetter: "data.subscriber.phoneNumber",
    },
    {
      headerName: "appeler et e-mail",
      field: "subscriber.callEmailPermissionStatus",
      cellRenderer: callEmailStatus,
    },
    {
      headerName: lang.query.Status,
      // field: "status",
      field: "_id",
      cellRenderer: checkStatus,
    },
    {
      headerName: lang.admin.offerHeader.city,
      valueGetter: "data.subscriber.city",
    },
    {
      headerName: lang.admin.offerHeader.provider,
      field: "provider",
    },
    { field: "name" },
    {
      headerName: lang.admin.offerHeader.monthlyPrice,
      field: "monthlyPrice",
      cellRenderer: (params) => params.value + " €/mois",
    },
    {
      headerName: lang.admin.offerHeader.yearlyPrice,
      field: "monthlyPrice",
      cellRenderer: (params) => (params.value * 12).toFixed(2) + " €/ans",
    },
    {
      headerName: lang.admin.offerHeader.dateSlot,
      maxWidth: 170,
      field: "dateSlot",
      cellRenderer: shortDateFormat,
    },
    {
      headerName: lang.admin.offerHeader.timeSlot,
      maxWidth: 135,
      field: "timeSlot",
    },
    {
      headerName: lang.admin.offerHeader.createdDate,
      field: "createdAt",
      cellRenderer: dateFormat,
    },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
    filterParams: {
      // buttons: ["reset", "apply"],
      debounceMs: 100,
    },
    floatingFilter: true,
    resizable: true,
  }));

  function setPagination(event) {
    event.preventDefault();
    setPaginationRow(event.target.value);
  }

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);
  return (
    <div>
      <ActivityModal
        show={showModal}
        onCancel={handleCloseModal}
        setShowAuthModal={handleCloseModal}
      />
      {/* Example using Grid's API */}
      <div className="tableOptions">
        <select
          className="form-control table_selection_control"
          name="pagination"
          onChange={(event) => setPagination(event)}
        >
          {lang.paginationRow.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="tbl_export_btn">
          <button className="btn btn-primary export_btn" onClick={onBtnExport}>
            {lang.offer.exportCSV}
          </button>
        </div>
      </div>
      <div className="ag-theme-alpine" style={{ width: "100%", height: 480 }}>
        <AgGridReact
          ref={gridRef} // Ref for accessing Grid's API
          rowData={tbody} // Row Data for Rows
          // rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          paginationPageSize={paginationRow}
          suppressExcelExport={true}
          sortingOrder={sortingOrder}
          pagination={true}
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
        />
      </div>
    </div>
  );
};

export default Offers;
