import { useEffect } from "react";
import { Offer } from "../../hooks/useAPI";
import EmptyBox from "../../UI/EmptyBox";
import OffersBox from "../../UI/OffersBox";
import OffersNavbar from "../../UI/OffersNavbar";
import Sidebar from "../../UI/Sidebar";

import classes from "./index.module.css";

interface Props {
  offers: Offer[];
}

function DisplayOffers(props: Props) {
  const offers = [...props.offers].sort(
    (a, b) => a.monthlyPrice - b.monthlyPrice
  );
  useEffect(() => {
    document.documentElement.style.setProperty("--white", "#fff");
  });
  return (
    <>
      <OffersNavbar />
      <div className="row displayOffersContainer">
        <div className="col-md-4 col-lg-4">
          <Sidebar />
        </div>
        <div className="col-md-8 col-lg-8">
          <div className={classes.container}>
            <OffersBox offers={offers} />
          </div>
        </div>  
      </div>
    </>
  );
}

export default DisplayOffers;
