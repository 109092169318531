// import { useState, PropsWithChildren, useEffect } from "react";

// import DatePicker from "react-date-picker/dist/entry.nostyle";
// import Icon from "./Icon";

// import classes from "./DateField.module.css";
// import "./DateField.css";

// interface Props extends PropsWithChildren {
//   getState?: (value: string) => void;
//   state?: Date;
//   minDate?: Date;
//   isInsured?: boolean | null;
// }

// function DateFieldInsure(props: Props) {
//   const [value, setValue] = useState<Date | undefined>(props.state);

//   useEffect(() => {
//     setValue(props.state);
//   }, [props.state]);

//   function changeHandler(value?: Date) {
//     if (!value) {
//       return;
//     }

//     setValue(value);

//     if (props.getState) {
//       props.getState(value.toISOString());
//     }
//     setTimeout(() => {
//       setValue(value);
//     });
//   }

//   return (
//     <div className={classes.container}>
//       <DatePicker
//         onChange={changeHandler}
//         value={value}
//         format="dd/MMM/yyyy" 
//         dayPlaceholder="JJ"
//         monthPlaceholder="MM"
//         yearPlaceholder="AAAA"
//         minDate={props.isInsured ? props.minDate : new Date()}
//         calendarIcon={<Icon name="calendarOutline" className="calendar-icon" />}
//         clearIcon={null}
//         showLeadingZeros={true}
//         openCalendarOnFocus={true}
//         locale="fr" 
//       />
//       {props.children}
//     </div>
//   );
// }

// export default DateFieldInsure;

import { useState, PropsWithChildren, useEffect } from "react";
import DatePicker from "react-date-picker/dist/entry.nostyle";
import Icon from "./Icon";
import { fr } from "date-fns/locale"; // Import French locale
import moment from "moment"; // Import moment for date manipulation

import classes from "./DateField.module.css";
import "./DateField.css";

interface Props extends PropsWithChildren {
  getState?: (value: string) => void;
  state?: Date;
  minDate?: Date;
  isInsured?: boolean | null;
}

function DateFieldInsure(props: Props) {
  const [value, setValue] = useState<Date | undefined>(props.state);

  useEffect(() => {
    if (props.state) {
      // Convert the input date to UTC when initializing
      const utcDate = moment.utc(props.state).toDate();
      setValue(utcDate);
    }
  }, [props.state]);

  function changeHandler(date?: Date) {
    if (!date) {
      return;
    }

    // Convert the selected date to UTC
    const utcDate = moment.utc(date).toDate();
    setValue(utcDate);

    if (props.getState) {
      props.getState(utcDate.toISOString());
    }
    setTimeout(() => {
      setValue(utcDate);
    });
  }

  return (
    <div className={classes.container}>
      <DatePicker
        onChange={changeHandler}
        value={value}
        format="dd/MM/yyyy"  // Abbreviated month format
        dayPlaceholder="JJ"
        monthPlaceholder="MM"
        yearPlaceholder="AAAA"
        minDate={props.isInsured ? props.minDate : new Date()}
        calendarIcon={<Icon name="calendarOutline" className="calendar-icon" />}
        clearIcon={null}
        showLeadingZeros={true}
        openCalendarOnFocus={true}
        locale={'fr'} // Set French locale
      />
      {props.children}
    </div>
  );
}

export default DateFieldInsure;
