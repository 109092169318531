export default {
  common: {
    firstName: "Prénom",
    lastName: "nom de famille",
    name: "nom",
    email: "E-mail",
    fullName: "Entrez le nom",
    phone: "Téléphone",
    enterEmail: "Entrez l'e-mail",
    query: "Requête",
    confirm: "Confirmer",
    cancel: "Annuler",
    back: "Dos",
    fullDetails: "Remplir les détails",
    submit: "Soumettre",
    success: "Succès",
    error: "Erreur",
    members: "Adhérents",
    continue: "Continuer",
    dashboard: "Tableau de bord",
    manageOffers: "Gérer les offres",
    manageUsersQueries: "Gérer les requêtes des utilisateurs",
    home: "Maison",
    subscribers: "Abonnées",
    totalSubscribers: "Nombre total d'abonnés", // Total subscribers
    insuredUsers: "Utilisateurs assurés", // Insured Users
    totalInsuredUsers: "Total des utilisateurs assurés", // Total insured users
    edit: "Modifier",
    verify: "Vérifié",
    notVerify: "Non vérifié",
    saveChanges: "Enregistrer les modifications", // Save Changes
    signOut: "se déconnecter", // sign out
    search: "Recherche"
  },
  Chatbot: {
    Chatbot: "Besoin d'aide ?",
    otherMessage: "Si vous avez d'autres questions...",
    askQuestion: "Poser une question",
    faqQuestions: [
      {
        parentId: 0,
        id: 1,
        faqTitle: "Générale",
        faqDescription: "Requêtes générales", // General queries
      },
      {
        parentId: 0,
        id: 2,
        faqTitle: "Installation et Intégration", // Installation and Integration
        faqDescription:
          "Requêtes sur l'intégration de Kommunicate dans toutes vos plateformes, telles que les sites web, CMS, applications mobiles natives et hybrides.",
        // Queries about integrating Kommunicate in all your platforms such as websites, CMS, native and hybrid mobile apps.
      },
      {
        parentId: 0,
        id: 3,
        faqTitle: "Facturation et Paiements", // Billing and Payments
        faqDescription:
          "Trouvez toutes vos réponses sur les prix, la facturation et autres questions liées aux paiements.", // Find all your answers to pricing, billing, and other payment-related queries.
      },
      {
        parentId: 1,
        id: 4,
        faqTitle: "Aucun agent disponible", // No agent
        faqDescription:
          "Aucun agent n'est actuellement disponible, veuillez soumettre votre demande.",
        // No agent is currently available, please submit your query.
      },
      {
        parentId: 2,
        id: 9,
        faqTitle: "Installation et Intégration", // Installation and Integration
        faqDescription:
          "Requêtes sur l'intégration de Kommunicate dans toutes vos plateformes, telles que les sites web.", // Queries about integrating Kommunicate in all your platforms such as websites.
      },
      {
        parentId: 9,
        id: 10,
        faqTitle: "Installation et Intégration", // Installation and Integration
        faqDescription:
          "Il est un fait établi de longue date qu'un lecteur sera distrait par le contenu lisible d'une page lorsqu'il regarde sa mise en page. L'intérêt d'utiliser Lorem Ipsum est qu'il a une distribution plus ou moins normale des lettres, contrairement à l'utilisation de 'Contenu ici, contenu ici', ce qui le fait ressembler à de l'anglais lisible.",
        // It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
      },
    ],
  },
  questions: {
    address: {
      title: "Votre ville ou code postal",
    },
  },
  offer: {
    offer: "Offres", // Offers
    confirmOffierModalTitle: "Confirmer l'offre", // Confirm Offer
    confirmOfferModalBody: "Êtes-vous sûr de vouloir continuer", // "Are sure you want to proceed"
    successALert: "Confirmé votre offre avec succès", // Confirmed your offer successfully
    notSendEmail:
      "J'ai confirmé votre offre mais je n'ai pas pu envoyer de mail de confirmation.", // Confirmed your offer but unable to send confirmation mail.
    notSelectSlot: "Veuillez sélectionner une date/créneau horaire approprié", // Please select any suitable date/time slot
    errorMessage:
      "Une erreur s'est produite lors de la confirmation de votre offre. Veuillez réessayer.", // An error occurred while confirming your offer. Please try again.
    recievedOffers: "Offres reçues", // Recieved Offers
    totalRecievedOffers: "Total des offres reçues", // Total recieved offers
    submittedOffers: "Offres soumises", // Submitted Offers
    exportCSV: "Exporter au format CSV", // Export CSV
  },
  admin: {
    admin: "Administratrice",
    subAdmin: "Sous-administrateur",
    login: "se connecter",
    password: "Mot de passe", // Password
    forgotPassword: "Mot de passe oublié", // Forgot Password?
    resetPassword: "Réinitialiser le mot de passe", // Reset Password
    changePassword: "Changer le mot de passe", // Change Password
    enterUserName: "Entrez votre email ou votre nom d'utilisateur", // Enter your email or username
    passwordPlaceholder: "Entrez votre mot de passe", // Enter your password
    currentPassword: "Mot de passe actuel", // Current Password
    currentPasswordPlaceholder: "Entrez votre mot de passe actuel", // Enter your current password
    newPassword: "New Password", // New Password
    newPasswordPlaceholder: "Entrez votre nouveau mot de passe", // Enter your new password
    confirmPassword: "Ressaisissez le nouveau mot de passe", // Re-enter New Password
    confirmPasswordPlaceholder: "Confirmez votre mot de passe", // Cofirm your password
    backToLogin: "Retour à la connexion", // Back to login
    offerHeader: {
      email: "Email",
      phoneNumber: "Numéro de téléphone",
      city: "Ville",
      provider: "Fournisseur",
      monthlyPrice: "Prix mensuel",
      yearlyPrice: "Prix annuel",
      dateSlot: "Plage de dates",
      timeSlot: "Plage horaire",
      createdDate: "Date de création",
    },
    addNew: "Ajouter un nouveau",
    selectRole: "Sélectionnez un rôle"
  },
  query: {
    usersName: "Nom de l'utilisateur", // Users Name
    usersEmail: "Email de l'utilisateur", // Users Email
    Status: "Statut", // Status
    Query: "Requête", // Query
    createdDate: "Date de création", // Create Date
    Action: "Action", // Action`
    feedbackForm: "Formulaire de commentaires", // Feedback Form
    usersQueries: "Requêtes des utilisateurs", // Users Queries
    queriesFeedback: "Retour sur les requêtes.", // Queries Feedback
    feedbackPlaceholder: "Entrez vos commentaires.", // Enter your feedback
  },
  manageAdmin: {
    manage: "Gérer l'administrateur", // Manage Admin
    role: "Rôle",
    addNewAdmin: "Ajouter un nouvel administrateur", // Add new admin
    addAdmin: "Ajouter un administrateur", // Add admin
    dataNotExist: "Les données n'existent pas!", // Data not exist
  },
  profile: {
    editProfile: "Modifier le profil", // Edit Profile
    profileImage: "Image de profil", // Profile image
    myProfile: "Mon profil", // My Profile
    noNotification: "Aucune notification", // No Notification
  },
  paginationRow: [
    { value: 10, label: "afficher 10 lignes" }, // show 10 rows
    { value: 20, label: "afficher 20 lignes" }, // show 20 rows
    { value: 25, label: "afficher 25 lignes" }, // show 25 rows
    { value: 50, label: "afficher 50 lignes" }, // show 50 rows
  ],
};

// Backend strings

// chatbotSuccess= "Your query has been successfully submitted. Our team will get back to you shortly",
// chatbotError= "Impossible de soumettre vos requêtes. Essayez après un certain temps"
// addError= "All fields are mandatory!","Tous les champs sont obligatoires !"

// // getChatbotMessage
// Toutes les requêtes de l'utilisateur = User's all queries
// Quelque chose s'est mal passé! = Something went wrong!
// Quelque chose s'est mal passé ! Réessayez ou contactez-nous. = Something went wrong! Try again or contact us.
// Échec de la récupération des notifications. =  Failed to retrieve notifications.
// Les données de notification n'existent pas ! = Notification data does not exist!
// L'accès à cet itinéraire est interdit ! = Access to this route is forbidden!
// Les données des requêtes n'existent pas ! = Queries data not exist!
// Vous êtes déjà abonné à cette offre. Veuillez sélectionner une autre offre. = You have already subscribed to this offer. Please select another offer.
