import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import navigationSlice from "./appStateSlices/navigationSlice";
import credentialsSlice from "./userDataSlices/credentialsSlice";
import membersSlice from "./userDataSlices/membersSlice";
import warrantiesSlice from "./userDataSlices/warrantiesSlice";

import selectedOffer from "./appStateSlices/selectedOffer";
import AuthCredential from "./appStateSlices/AuthCredentialsSlice";
import UserAuthCredential from "./appStateSlices/userAuthSlice";

const persistConfig = {
    key: "root",
    storage,
};
const persistedReducer = persistReducer(persistConfig, UserAuthCredential.reducer);

const store = configureStore({

  reducer: {
    userData: combineReducers({
      members: membersSlice.reducer,
      warranties: warrantiesSlice.reducer,
      credentials: credentialsSlice.reducer,
    }),
    appState: combineReducers({
      navigation: navigationSlice.reducer,
    }),
    confirmOffer: combineReducers({
      selectedOffer: selectedOffer.reducer,
    }),
    authCredential: combineReducers({
      auth: AuthCredential.reducer,
    }),
    userAuthCredential: combineReducers({
      userAuth: UserAuthCredential.reducer,
    }),
    user: persistedReducer,
  },
});
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
