import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";

import "./index.css";
import store, { persistor } from "./store";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ToastContainer
          className="foo" style={{ width: "auto" }}
        />
      </QueryClientProvider>
    </PersistGate>
  </Provider>
);
