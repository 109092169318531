import { credentialsActions } from "../../../../store/userDataSlices/credentialsSlice";
import { useAppDispatch } from "../../../../store/stateHooks";
// import DateField from "../../../UI/DateField";
import DateFieldInsure from "../../../UI/DateFieldInsure";
import FieldLabel from "../../../UI/FieldLabel";
import QuestionBox from "../../../UI/QuestionBox";
import moment from "moment";

interface Props {
  value?: string;
  expiryMonth?: number;
  isInsured?: boolean | null;
}
function InsureDate(props: Props) {
  const dispatch = useAppDispatch();
  const months: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentDate = moment();
  const index: number = props.expiryMonth ?? 0;
  const selectedMonth = months[index];

  const selectedDate = moment(selectedMonth, "MMMM").year(currentDate.year());

  if (selectedDate.isBefore(currentDate, "month")) {
    selectedDate.year(currentDate.year() + 1);
  }
  // Get the final date
  var finalDate = selectedDate.toDate();

  function answerHandler(value: string) {
    dispatch(credentialsActions.setInsureDate(value));
  }
  return (
    <QuestionBox
      targetState="credentials"
      action="setInsureDate"
      scrollToIt={true}
    >
      <FieldLabel>A quelle date souhaitez-vous être assuré(e) ?</FieldLabel>
      <DateFieldInsure
        getState={answerHandler}
        minDate={finalDate}
        isInsured={props.isInsured}
        state={props.value ? new Date(props.value) : undefined}
      />
    </QuestionBox>
  );
}

export default InsureDate;
