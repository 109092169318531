import { PropsWithChildren } from "react";

import Icon from "./Icon";

import classes from "./OffersHeading.module.css";

interface Props extends PropsWithChildren {
  offersNumber: number;
  highestOfferID?: string;
  lowestOfferID?: string;
}

function scrollToTopHandler() {
  window.scrollTo(0, 0);
}

function scrollToBottomHandler() {
  window.scrollTo(0, document.body.offsetHeight);
}

function OffersHeading(props: Props) {
  return (
    <div className={classes.heading}>
      <Icon name="albumsOutline" className={classes.icon} />
      <h1>
        <span style={{fontWeight:"700"}} className={classes.text}>{props.children}</span>
        <span className={classes.number}>
          ({props.offersNumber} offres disponibles)
        </span>
      </h1>
      <span
        className={`${classes.badge} ${classes.lowest}`}
        onClick={scrollToTopHandler}
      >
        Plus bas
      </span>
      <span
        className={`${classes.badge} ${classes.highest}`}
        onClick={scrollToBottomHandler}
      >
        Plus élevé
      </span>
    </div>
  );
}

export default OffersHeading;
