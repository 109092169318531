import { useState } from "react";
import { Link } from "react-router-dom";
import { navigationActions } from "../../store/appStateSlices/navigationSlice";
import { useAppDispatch, useAppSelector } from "../../store/stateHooks";
import NavbarBox from "./NavbarBox";

import classes from "./OffersNavbar.module.css";
import { AuthModal } from "../modals";

function OffersNavbar() {
  const { user } = useAppSelector((state) => state.user);
  const isAuthenticated = user.auth;
  const [showAuthModal, setShowAuthModal] = useState(false);
  const dispatch = useAppDispatch();

  function goBackHandler() {
    dispatch(navigationActions.setActiveAppPage(1));
  }

 const handleCancel = () => {
    setShowAuthModal(false);
  };

  return (
    <NavbarBox>
       <AuthModal
        show={showAuthModal}
        onCancel={handleCancel}
        setShowAuthModal={setShowAuthModal}
        login={showAuthModal}
      />
      <div style={{ display: 'flex', gap: '15px' }}>
        <div className={classes.restart} onClick={goBackHandler}>
          Retourner
        </div>
        {
          isAuthenticated ? (<Link to="/dashboard" className={classes.restart}>
            Tableau de bord
          </Link>) : (<div onClick={() => setShowAuthModal(true)} className={classes.restart}>
            se connecter
          </div>)
        }
      </div>
    </NavbarBox>
  );
}

export default OffersNavbar;
