import { Link, useLocation } from "react-router-dom";
import { useMemo } from "react";
import lang from "../../../utils/language/french"
function SideBar() {
  const location = useLocation();
  const sidebarData = useMemo(
    () => [
      {
        name: lang.common.dashboard, // Dashboard
        icon: "bi bi-grid",
        path: "/admin/dashboard",
      },
      {
        name: lang.common.manageOffers, // Manage offers
        icon: "bi bi-layout-text-window-reverse",
        path: "/admin/submitted-offers",
      },
      {
        name: lang.common.manageUsersQueries, // Manage Users Queries
        icon: "bi bi-layout-text-window-reverse",
        path: "/admin/users/queries",
      },
    ],
    []
  );

  return (
    <aside id="sidebar" className="sidebar">
      <ul className="sidebar-nav" id="sidebar-nav">
        {sidebarData.map((menu) => (
          <li className="nav-item" key={menu.path}>
            <Link
              to={menu.path}
              className={`nav-link ${
                location.pathname === menu.path ? "" : "collapsed"
              }`}
            >
              <i className={menu.icon} />
              <span>{menu.name}</span>
            </Link>
          </li>
        ))}
      </ul>
    </aside>
  );
}

export default SideBar;
