import Sidebar from "./Sidebar";
import Header from "./header";

import { useEffect, useState } from "react";

const Layout = () => {
  const [showSidebar, setShowSidebar] = useState(true);

  function handleSize() {
    if (window.innerWidth > 768) {
      setShowSidebar(true);
    } else {
      setShowSidebar(false);
    }
  }

  useEffect(() => {
    import("../../Admin/assets/js/main.js");
    document.documentElement.style.setProperty("--white", "#f6f9ff");

    handleSize();
    window.addEventListener("resize", handleSize);

    return () => {
      window.removeEventListener("resize", handleSize);
    };
  }, []);
  return (
    <div>
      <Header showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
    </div>
  );
};

export default Layout;
