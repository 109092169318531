import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store/stateHooks";
import lang from "../../../utils/language/french"
function Dashboard() {
  const [countOffers, setCountOffers] = useState(0);
  const [countSubscribers, setSountSubscribers] = useState(0);
  const { auth } = useAppSelector((state) => state.authCredential.auth);
  const SERVER_URL = process.env.REACT_APP_API_SERVER;

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${auth.data.token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${SERVER_URL}/api/v1/admin/dashboard/counting/data/${auth.data.admin_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setCountOffers(result.data.total_submitted_offers);
        setSountSubscribers(result.data.total_subscribers);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>{lang.common.dashboard}</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard">{lang.common.home}</Link>
            </li>
            <li className="breadcrumb-item active">{lang.common.dashboard}</li>
          </ol>
        </nav>
      </div>
      <section className="section dashboard">
        <div className="row">
          <div className="col-lg-12">
            <div className="row">
              <div className="col-xxl-4 col-md-4">
                <div className="card info-card sales-card">
                  <div className="card-body">
                    <h5 className="card-title">{lang.offer.recievedOffers}</h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        {/* <i className="bi bi-people"></i> */}
                        <i className="bi bi-clipboard-check"></i>
                      </div>
                      <div className="ps-3">
                        <h6>{countOffers}</h6>
                        <span className="text-muted small pt-2 ps-1">
                          {lang.offer.totalRecievedOffers}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-md-4">
                <div className="card info-card customers-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      {lang.common.subscribers}
                      {/* <!-- <span>| This Year</span> --> */}
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <i className="bi bi-people"></i>
                      </div>
                      <div className="ps-3">
                        <h6>{countSubscribers}</h6>
                        <span className="text-muted small pt-2 ps-1">
                          {lang.common.totalSubscribers}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-md-4">
                <div className="card info-card revenue-card">
                  <div className="card-body">
                    <h5 className="card-title">
                      {lang.common.insuredUsers}
                      {/* <!-- <span>| This Year</span> --> */}
                    </h5>
                    <div className="d-flex align-items-center">
                      <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        {/* <i className="bi bi-people"></i> */}
                        <i className="bi bi-person-fill-check"></i>
                      </div>
                      <div className="ps-3">
                        <h6>0</h6>
                        <span className="text-muted small pt-2 ps-1">
                          {lang.common.totalInsuredUsers}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Dashboard;
