import React from "react";
import { NoInternetImage } from "../Admin/assets/svg";
import { Link } from "react-router-dom";
const PageNotFound: React.FC = () => {
    const PageNotFound = require("../Admin/assets/img/pageNotFound.png");
    return (
        <div className="vh-100" style={{ background: "#ebfdfd" }}>
            <div className="p-3 d-flex">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="108"
                    height="37"
                    viewBox="0 0 108 37"
                    fill="none"
                >
                    <g clipPath="url(#clip0)">
                        <path
                            d="M34.8626 22.1741C35.4423 24.3871 37.4516 25.0625 39.0353 25.0625C40.3107 25.0625 41.6243 24.5751 41.6243 23.5992C41.6243 22.9621 41.2382 22.5118 40.1565 22.1358L36.6794 20.7108C31.9652 19.0977 31.7333 16.0596 31.7333 15.1962C31.7333 11.333 35.2498 9.30685 39.4991 9.30685C41.7785 9.30685 44.7918 9.90682 46.6469 13.3581L42.2029 15.3459C41.7008 13.8083 40.2713 13.5078 39.382 13.5078C38.3003 13.5078 37.1803 14.1077 37.1803 15.0837C37.1803 15.9088 38.0301 16.359 39.0353 16.7339L42.0104 17.8595C46.6852 19.172 47.1107 22.1358 47.1107 23.4112C47.1107 27.3498 43.3634 29.376 38.9971 29.376C36.0603 29.376 32.0429 28.4755 30.6898 24.4614L34.8626 22.1741Z"
                            fill="#263238"
                        ></path>
                        <path
                            d="M61.753 10.2445H65.7716L69.3646 21.3107L72.8034 9.71997H79.1397L77.5942 14.1089L72.0695 28.9629H67.1629L63.7623 18.0464L60.3617 28.9629H55.455L49.9304 14.1077L48.3849 9.71884H54.7211L58.16 21.3096L61.753 10.2445Z"
                            fill="#263238"
                        ></path>
                        <path
                            d="M85.2823 9.30911C87.3299 9.30911 88.953 10.6216 88.953 12.4981C88.953 14.3363 87.3299 15.6116 85.2823 15.6116C83.1965 15.6116 81.651 14.3363 81.651 12.4981C81.6498 10.6216 83.1954 9.30911 85.2823 9.30911ZM82.3455 28.9629V17.5995H88.102V28.9629H82.3455Z"
                            fill="#263238"
                        ></path>
                        <path
                            d="M95.7519 22.1741C96.3316 24.3871 98.3408 25.0625 99.9246 25.0625C101.2 25.0625 102.514 24.5751 102.514 23.5992C102.514 22.9621 102.128 22.5118 101.046 22.1358L97.5687 20.7108C92.8545 19.0977 92.6226 16.0596 92.6226 15.1962C92.6226 11.333 96.1379 9.30685 100.388 9.30685C102.668 9.30685 105.681 9.90682 107.536 13.3581L103.093 15.3459C102.591 13.8083 101.162 13.5078 100.272 13.5078C99.1907 13.5078 98.0707 14.1077 98.0707 15.0837C98.0707 15.9088 98.9205 16.359 99.9258 16.7339L102.901 17.8595C107.576 19.172 108.001 22.1358 108.001 23.4112C108.001 27.3498 104.254 29.376 99.8875 29.376C96.9507 29.376 92.9333 28.4755 91.5803 24.4614L95.7519 22.1741Z"
                            fill="#263238"
                        ></path>
                        <path
                            d="M16.7913 9.38295C17.0851 7.13299 15.4447 5.07775 13.1272 4.79245C10.8097 4.50715 8.69282 6.09983 8.39896 8.34979C8.1051 10.5998 9.74556 12.655 12.063 12.9403C14.3805 13.2256 16.4974 11.6329 16.7913 9.38295Z"
                            fill="#263238"
                        ></path>
                        <path
                            d="M16.2688 18.8163C15.1082 19.6369 13.9013 20.3258 12.7129 20.9607C12.1459 21.2623 11.5824 21.5539 11.0271 21.8387C9.89433 21.2004 8.75346 20.444 7.65665 19.5311C3.48622 16.0551 -0.0430609 10.2952 -0.0001623 0C5.14303 11.2238 9.14419 14.2113 13.8955 16.072C14.3975 16.2678 14.9077 16.4524 15.4294 16.6326C15.4294 16.6326 15.6381 17.2021 16.2688 18.8163Z"
                            fill="#263238"
                        ></path>
                        <path
                            d="M21.898 11.4073C21.5351 12.8639 20.8325 14.2822 19.8806 15.5103C18.9334 16.7462 17.7658 17.7897 16.5449 18.6711C16.4777 18.7195 16.4116 18.7679 16.3421 18.8163C15.1815 19.6369 13.9745 20.3258 12.7861 20.9607C12.2192 21.2623 11.6557 21.5539 11.1003 21.8387C9.18959 22.818 7.37046 23.7376 5.81567 24.9083C4.81625 25.6659 3.92814 26.5371 3.18727 27.5964C2.81625 28.1243 2.48002 28.6961 2.17973 29.3062C1.87944 29.9152 1.61509 30.5624 1.34843 31.2356C1.18727 30.5332 1.12234 29.8083 1.14089 29.0743C1.15828 28.3393 1.26263 27.5941 1.46553 26.8602C1.86669 25.3901 2.6748 23.9864 3.70901 22.8191C4.74205 21.6439 5.96408 20.6905 7.20234 19.8733C7.37741 19.7573 7.55364 19.6448 7.72988 19.5333C8.80118 18.8591 9.8864 18.2704 10.9449 17.7075C11.9849 17.156 12.9994 16.6258 13.9676 16.0754C15.2997 15.3178 16.545 14.5197 17.6452 13.5685C18.5971 12.7468 19.4435 11.8069 20.1681 10.7139C20.531 10.168 20.8684 9.58826 21.1734 8.97478C21.4806 8.36355 21.76 7.72194 22.0499 7.05893C22.1821 7.76584 22.2296 8.4885 22.2052 9.21792C22.1832 9.94284 22.0812 10.6801 21.898 11.4073Z"
                            fill="#263238"
                        ></path>
                        <path
                            d="M23.7345 17.1751C23.3716 18.6317 22.669 20.05 21.7171 21.2781C20.7698 22.5141 19.6023 23.5575 18.3814 24.4389C18.3142 24.4873 18.2481 24.5357 18.1785 24.5841C17.018 25.4047 15.811 26.0936 14.6226 26.7285C14.0556 27.0301 13.4922 27.3217 12.9368 27.6065C11.0261 28.5858 9.20694 29.5054 7.65216 30.6761C6.65274 31.4337 5.76463 32.3049 5.02376 33.3642C4.65274 33.8921 4.31651 34.4639 4.01622 35.074C3.71593 35.683 3.45158 36.3302 3.18491 37.0034C3.02376 36.301 2.95883 35.576 2.97738 34.8421C2.99477 34.1071 3.09912 33.3619 3.30202 32.628C3.70318 31.1579 4.51129 29.7542 5.54549 28.5869C6.57854 27.4117 7.80057 26.4583 9.03883 25.6411C9.2139 25.5252 9.39013 25.4126 9.56636 25.3012C10.6377 24.628 11.7229 24.0382 12.7814 23.4754C13.8214 22.9238 14.8359 22.3936 15.804 21.8432C17.1362 21.0856 18.3814 20.2875 19.4817 19.3364C20.4336 18.5146 21.28 17.5747 22.0046 16.4817C22.3675 15.9358 22.7049 15.3561 23.0098 14.7426C23.3171 14.1314 23.5965 13.4898 23.8864 12.8268C24.0185 13.5337 24.0661 14.2563 24.0417 14.9857C24.0209 15.7107 23.9177 16.4468 23.7345 17.1751Z"
                            fill="#263238"
                        ></path>
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width="108" height="37" fill="white"></rect>
                        </clipPath>
                    </defs>
                </svg>
                <div className="text-center p-3 d-flex row align-items-center">
                    <h1>😞 Page non trouvée 😞</h1>
                    <p>Désolé, la page que vous recherchez est introuvable. Veuillez vérifier l'URL ou revenir à la page d'accueil.</p>
                </div>
            </div>
            <div style={{ height: "75%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img src={PageNotFound} alt="Page Not Found" style={{ maxWidth: '100%', maxHeight: '70%' }} />
                <Link to="/" className="btn btn-primary mt-3">Aller à la maison</Link>
            </div>

        </div>
    );
};

export default PageNotFound;
