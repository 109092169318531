// src/components/Dashboard.js
import React from "react";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { getAPI } from "../../../apis/Services";
import lang from "../../../utils/language/french";
import showToast from "../../../utils/toast";
import { removeSessionData } from "../../../apis/auth.atom";
import classes from "../../UI/Offer.module.css";
import getWarrantiesIcons from "../../helpers/getWarrantiesIcons";
import WarrantyLevel from "../../UI/WarrantyLevel";
import { api } from "../../../utils/storage";
import { UserAuthActions } from "../../../store/appStateSlices/userAuthSlice";
import { useAppDispatch } from "../../../store/stateHooks";
import LoadingSpinner from "../../UI/LoadingSpinner";
import { getWarrantyLevelName } from "../../../utils/warrantyLevel";
import "./style.css";

function Dashboard() {
  const [rowData, setRowData] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const warranties = getWarrantiesIcons();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  React.useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const res = await getAPI(api.myOffer);
        if (res.status === 200) {
          // Function to update offers with warranties
          const result = res.offers.map((offer) => {
            // Clone the offer to avoid mutating the original object
            const controlledOffer = { ...offer };
            // Check if subscriber data exists and is valid
            if (
              controlledOffer.subscribers &&
              controlledOffer.subscribers.warranties
            ) {
              controlledOffer.warranties = {
                medicalCare: {
                  level: getWarrantyLevelName(
                    controlledOffer.subscribers.warranties.medicalCare?.level
                  ),
                },
                dental: {
                  level: getWarrantyLevelName(
                    controlledOffer.subscribers.warranties.dental?.level
                  ),
                },
                visual: {
                  level: getWarrantyLevelName(
                    controlledOffer.subscribers.warranties.visual?.level
                  ),
                },
                hearing: {
                  level: getWarrantyLevelName(
                    controlledOffer.subscribers.warranties.hearing?.level
                  ),
                },
                hospitalization: {
                  level: getWarrantyLevelName(
                    controlledOffer.subscribers.warranties.hospitalization
                      ?.level
                  ),
                },
              };
            }
            return controlledOffer;
          });

          setRowData(result);
        } else {
          showToast(res.message, "error");
        }
      } catch (err) {
        showToast(err.message, "error");
        const user = {
          auth: false,
        };
        dispatch(UserAuthActions.setUserAuth(user));
        removeSessionData();
        navigate("/");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const OfferCard = ({ props }) => {
    const levels = Object.values(props?.warranties).map((warranty) => {
      return warranty.level;
    });
    return (
      <motion.div
        className="card shadow-sm p-4 mb-4"
        whileHover={{ scale: 1.05 }}
        transition={{ type: "spring", stiffness: 300 }}
        onClick={() => navigate(`/offer-details/${props._id}`)}
      >
        <div className="d-flex justify-content-between flex-column flex-sm-row align-items-center gap-3">
          {/* Subscriber Details */}
          <div
            className="card border-light align-items-center"
            style={{ width: "350px" }}
          >
            <div className="card-body">
              {props.subscribers ? (
                <>
                  <h5 className="card-title mb-2">
                    {props.subscribers.firstName} {props.subscribers.lastName} :{" "}
                    {props.subscribers.status}
                  </h5>
                  <p className="card-text">
                    <strong>Email:</strong> {props.subscribers.email}
                  </p>
                  <p className="card-text">
                    <strong>Phone:</strong> {props.subscribers.phoneNumber}
                  </p>
                  <p className="card-text ">
                    <strong>Birth Date:</strong>{" "}
                    {new Date(props.subscribers.birthDate).toLocaleDateString()}
                    <strong style={{ marginLeft: 5 }}>Gender:</strong>{" "}
                    {props.subscribers.gender}
                  </p>
                  <p className="card-text">
                    <strong>City:</strong> {props.subscribers.city},{" "}
                    {props.subscribers.postalCode}
                  </p>
                  {/* <p className="card-text">
                    <strong>Gender:</strong> {props.subscribers.gender}
                  </p> */}
                  <p className="card-text">
                    <strong>Activity:</strong> {props.subscribers.activity}
                  </p>
                  {/* <p className="card-text">
                    <strong>Status:</strong> {props.subscribers.status}
                  </p> */}
                  <p className="card-text">
                    <strong>Social Regime:</strong>{" "}
                    {props.subscribers.socialRegime}
                  </p>
                </>
              ) : (
                <p className="text-center">No subscribers available.</p>
              )}
            </div>
          </div>

          {/* Provider Information */}
          <div className="d-flex flex-column align-items-center text-center me-2 flex-grow-1">
            <img
              className={classes.logo}
              style={{ width: "13rem" }}
              src={`${process.env.PUBLIC_URL}/assets/img/${props.provider}-Logo.png`}
              alt={`${props.provider} Logo`}
            />
            <h4 className="mt-3 mb-2">
              {props.name || props.label || props.provider}
            </h4>
            {props.code && <p className="text-muted">{props.code}</p>}
          </div>

          {/* Warranty Information */}
          <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
            <div className="d-flex align-items-center justify-content-center gap-2">
              {warranties.map((warranty, i) => (
                <WarrantyLevel
                  key={nanoid(6)}
                  icon={warranty.icon}
                  level={levels[i]}
                />
              ))}
            </div>
          </div>

          {/* Price Information */}
          <div className="text-end flex-shrink-0">
            <div className="fs-4 fw-bold mb-1">
              {props.monthlyPrice} € /mois
            </div>
            <div className="text-muted">
              Budget estimé: {Math.round(props.monthlyPrice * 12)} €/ans
            </div>
          </div>
        </div>
      </motion.div>
    );
  };
  return (
    <main id="main" className="main">
      <div className="justify-content-center">
        <div className="">
          {isLoading ? (
            <div className="d-flex justify-content-center">
              <LoadingSpinner />
            </div>
          ) : rowData.length === 0 ? (
            <div>No Offer you have seleceted now....</div>
          ) : (
            rowData.map((offer) => (
              <div key={offer._id}>
                <OfferCard props={offer} />
              </div>
            ))
          )}
        </div>
      </div>
    </main>
  );
}

export default Dashboard;
