import { useEffect, useState } from "react";
import "./styles.css";
import lang from "../../utils/language/french";
const ConfirmationModal = ({ show, title, message, onConfirm, onCancel }) => {
  const [fadeType, setFadeType] = useState("");

  useEffect(() => {
    if (show) {
      setFadeType("in");
    } else {
      setFadeType("out");
    }
  }, [show]);

  const handleAnimationEnd = () => {
    if (fadeType === "out") {
      onCancel();
    }
  };

  return (
    <div
      className={`modal fade ${
        show ? "show d-block" : "d-none"
      } fade-${fadeType}`}
      tabIndex="-1"
      role="dialog"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      onAnimationEnd={handleAnimationEnd}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => setFadeType("out")}
            ></button>
          </div>
          <div className="modal-body">
            <p>{message}</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setFadeType("out")}
            >
              {lang.common.cancel}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                setFadeType("out");
                onConfirm();
              }}
            >
              {lang.common.confirm}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
