import React, { useState, useRef, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { useAppSelector } from "../../../store/stateHooks";
import lang from "../../../utils/language/french";

const ManageUsersQueriesTable = ({ tbody }) => {
  const gridRef = useRef();
  const submitBtnRef = useRef();
  const messagesRef = useRef();
  const [paginationRow, setPaginationRow] = useState(10);
  const [rowHeight, setRowHeight] = useState(100);
  const SERVER_URL = process.env.REACT_APP_API_SERVER;
  const { auth } = useAppSelector((state) => state.authCredential.auth);

  const sortingOrder = useMemo(() => ["desc", "asc", null], []);

  const checkStatus = (params) => {
    const status = params.value;
    return status === 0 ? (
      <span className="badge rounded-pill bg-danger">Pas de réponse</span>
    ) : (
      <span className="badge rounded-pill bg-success">répondue</span>
    );
  };

  const [selectedData, setSelectedData] = useState(0);
  const [filteredData, setFilteredData] = useState({});
  const [feedbackQuery, setFeedbackQuery] = useState("");

  const buttonClicked = useCallback(async () => {
    try {
      submitBtnRef.current.innerHTML = `<div style="height:22px;width:22px" class="spinner-border text-light"></div>`;

      const BASE_URL = `${SERVER_URL}/api/v1/queries/send-feedback/${auth.data.admin_id}`;
      const response = await fetch(BASE_URL, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.data.token}`,
        },
        body: JSON.stringify({ id: selectedData, feedbackQuery }),
      });

      const resData = await response.json();
      submitBtnRef.current.innerHTML = `Soumise`;

      if (response.status === 200) {
        messagesRef.current.innerHTML = `
          <div class="alert alert-success alert-dismissible fade show">
            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
            <strong>${lang.common.success}!</strong> ${resData.message}
          </div>`;
        setTimeout(() => {
          messagesRef.current.innerHTML = "";
          document.getElementById("closeModal").click();
          setFeedbackQuery("");
        }, 1000);
      } else {
        messagesRef.current.innerHTML = `
          <div class="alert alert-danger alert-dismissible fade show">
            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
            <strong>${lang.common.error}!</strong> ${resData.message}
          </div>`;
      }
    } catch (error) {
      submitBtnRef.current.innerHTML = lang.common.submit;
    }
  }, [selectedData, feedbackQuery, SERVER_URL, auth.data]);

  const setRecordId = useCallback((recordId) => {
    setSelectedData(recordId);
    gridRef.current.api.forEachNodeAfterFilter((rowNode) => {
      if (rowNode.data._id === recordId) {
        setFilteredData(rowNode.data);
      }
    });
  }, []);

  const actionButton = (params) => (
    <button
      onClick={() => setRecordId(params.value)}
      className="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#editModal"
    >
      {lang.common.edit}
    </button>
  );
  
 const shortDateFormat = (params) => {
    const dateTimes = new Date(params.value);

    // Correct format for the year (yyyy instead of YYYY)
    const formattedEndDateTime = format(dateTimes, "dd MMM yyyy", {
      locale: fr,
    });
    return formattedEndDateTime;
  };

  const columnDefs = useMemo(
    () => [
      { headerName: lang.query.usersName, field: "name" },
      { headerName: lang.query.usersEmail, field: "email" },
      { headerName: lang.query.Status, field: "status", cellRenderer: checkStatus },
      { headerName: lang.query.Query, field: "queries" },
      { headerName: lang.query.createdDate, field: "created_at", 
      cellRenderer: shortDateFormat, },
      {
        headerName: lang.query.Action,
        field: "_id",
        maxWidth: 100,
        cellRenderer: actionButton,
      },
    ],
    [setRecordId]
  );

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      filterParams: { debounceMs: 100 },
      floatingFilter: true,
      resizable: true,
    }),
    []
  );

  const setPagination = useCallback((event) => {
    setPaginationRow(Number(event.target.value));
  }, []);

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);
const handleCancel = ()=>{
  setFeedbackQuery("");
  submitBtnRef.current.innerHTML = `Soumise`
}
  return (
    <div>
      <div className="tableOptions">
        <select
          className="form-control table_selection_control"
          name="pagination"
          onChange={setPagination}
          value={paginationRow}
        >
          {lang.paginationRow.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="tbl_export_btn">
          <button className="btn btn-primary export_btn" onClick={onBtnExport}>
            {lang.offer.exportCSV}
          </button>
        </div>
      </div>
      <div className="ag-theme-alpine" style={{ width: "100%", height: 480 }}>
        <AgGridReact
          ref={gridRef} // Ref for accessing Grid's API
          rowData={tbody} // Row Data for Rows
          getRowHeight={rowHeight}
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          paginationPageSize={paginationRow}
          suppressExcelExport={true}
          sortingOrder={sortingOrder}
          pagination={true}
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
        />
      </div>

      <div className="modal fade" id="editModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-4 pt-2">
              <h5 className="card-title">{lang.query.feedbackForm}</h5>
              <div ref={messagesRef}></div>
              <form className="row g-3">
                <div className="col-md-6">
                  <label className="form-label">{lang.common.name}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={filteredData.name || ""}
                    readOnly
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">{lang.common.email}</label>
                  <input
                    type="email"
                    className="form-control"
                    value={filteredData.email || ""}
                    disabled
                    readOnly
                  />
                </div>
                <div className="col-12">
                  <label className="form-label">{lang.query.usersQueries}</label>
                  <textarea
                    className="form-control"
                    value={filteredData.queries || ""}
                    rows={5}
                    readOnly
                    disabled
                  ></textarea>
                </div>
                <div className="col-12">
                  <label className="form-label">{lang.query.queriesFeedback}</label>
                  <textarea
                    className="form-control"
                    placeholder={lang.query.feedbackPlaceholder}
                    rows={4}
                    required
                    value={feedbackQuery} // Bind the value of the textarea to feedbackQuery state
                    onChange={(e) => setFeedbackQuery(e.target.value)}
                  ></textarea>
                </div>
                <div className="text-end">
                  <button
                    type="button"
                    ref={submitBtnRef}
                    onClick={buttonClicked}
                    className="btn btn-primary mx-2"
                  >
                    {lang.common.submit}
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger mx-2"
                    data-bs-dismiss="modal"
                    id="closeModal"
                    onClick={handleCancel} // Reset feedbackQuery when closing the modal
                  >
                    {lang.common.cancel}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageUsersQueriesTable;
