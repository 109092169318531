import { nanoid } from "nanoid";
import { useAppSelector } from "../../store/stateHooks";
import getUsername from "../helpers/getUsername";

import Icon from "./Icon";

import classes from "./Sidebar.module.css";

function Sidebar() {
  // const [showProfile, setShowProfile] = useState(true);

  const subscriberData = useAppSelector((state) => state.userData);

  // function profileSelectionHandler() {
  //   setShowProfile(true);
  // }

  // function sortSelectionHandler() {
  //   setShowProfile(false);
  // }

  return (
    <div className={classes.container}>
      <div className={classes["control-bar"]}>
        {/* <div
          className={showProfile ? classes.selected : ""}
          onClick={profileSelectionHandler}
        >
          <Icon name="personCircleOutline" className={classes.icon} />
          <span>Mon Profil</span>
        </div>
        <div
          className={!showProfile ? classes.selected : ""}
          onClick={sortSelectionHandler}
        >
          <Icon name="funnelOutline" className={classes.icon} />
          <span>Trier Par</span>
        </div> */}
      </div>
      <div className={classes["profile-tab"]}>
        <div>
          <p className={classes.title}>
            <Icon name="personOutline" className={classes["profile-icons"]} />{" "}
            Assuré(e){" "}
          </p>
          <p className={classes.content}>
            {getUsername(
              subscriberData.members.gender,
              subscriberData.credentials.firstName,
              subscriberData.credentials.lastName
            )}
          </p>
        </div>
        <div>
          <p className={classes.title}>
            <Icon name="mailOutline" className={classes["profile-icons"]} />{" "}
            É-mail{" "}
          </p>
          <p className={classes.content}>{subscriberData.credentials.email}</p>
        </div>
        <div>
          <p className={classes.title}>
            <Icon
              name="navigateCircleOutline"
              className={classes["profile-icons"]}
            />{" "}
            Adresse
          </p>
          <p className={classes.content}>
            {subscriberData.credentials.postalCode},{" "}
            {subscriberData.credentials.city}, FRANCE
          </p>
        </div>
        <div>
          <p className={classes.title}>
            <Icon name="optionsOutline" className={classes["profile-icons"]} />{" "}
            Remboursement
          </p>
          {Object.keys(subscriberData.warranties).map((key) => (
            <p style={{marginBottom:"0px"}} className={classes.content} key={nanoid(6)}>
              {key}
              {" -> "}
              {
                subscriberData.warranties[
                  key as keyof typeof subscriberData.warranties
                ].level
              }
            </p>
          ))}
        </div>
      </div>
      {/* <div className={classes["sort-tab"]}>This is the sort tab!</div> */}
    </div>
  );
}

export default Sidebar;
