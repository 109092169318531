import { PropsWithChildren } from "react";

import Logo from "./Logo";

import classes from "./NavbarBox.module.css";

function NavbarBox(props: PropsWithChildren) {
  return (
    <nav className={classes.container}>
      <div className={classes.brand}>
        <Logo />
      </div>
      {props.children}
    </nav>
  );
}

export default NavbarBox;
