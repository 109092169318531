import { RxDashboard } from "react-icons/rx";
import { MdLogout, MdOutlineQuiz } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { FaHome } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { removeSessionData } from "../../../apis/auth.atom";
import { useAppDispatch } from "../../../store/stateHooks";
import lang from "../../../utils/language/french";
import { UserAuthActions } from "../../../store/appStateSlices/userAuthSlice";
import { navigationActions } from "../../../store/appStateSlices/navigationSlice";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  function signOut() {
    const user = {
      auth: false,
    };
    dispatch(UserAuthActions.setUserAuth(user));
    removeSessionData();
    navigate("/");
  }
  const location = useLocation();
    function restartPage() {
    dispatch(navigationActions.setActiveFormPage(1));
  }
  return (
    <aside
      id="sidebar"
      className="sidebar d-flex flex-column"
      style={{ width: "240px" }}
    >
      <ul className="sidebar-nav flex-grow-1" id="sidebar-nav">
        {sidebarcontent.map((menu) => (
          <li className="nav-item" key={menu.path}>
            <Link
              to={menu.path}
              onClick={()=> menu.check && restartPage()}
              className={`nav-link g-3 ${
                location.pathname === menu.path ? "" : "collapsed"
              }`}
            >
              {/* <i className={menu.icon} /> */}
              <span className="me-3 ms-3">
                <menu.icon style={{ fontSize: '20px' }}/>
              </span>

              <span>{menu.name}</span>
            </Link>
          </li>
        ))}
      </ul>
      <div className="border-top mt-auto">
        <button
          className="btn w-100 text-start px-3 py-2 d-flex align-items-center text-danger"
          onClick={() => signOut()}
        >
          <MdLogout className="me-2" />
          <span>{lang.common.signOut}</span>
        </button>
      </div>
    </aside>
  );
};

const sidebarcontent = [
  { name: "Mon offre", icon: RxDashboard, path: "/dashboard", check: false },
  {name: "Maison", icon: FaHome, path: "/", check: true},
  { name: "Profil", icon: CgProfile, path: "/profile", check: false },
  { name: "mot de passe", icon: MdOutlineQuiz, path: "/reset-password", check: false },
];

export default Sidebar;
