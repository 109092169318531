import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import PageManager from "../components/pages/PageManager";

import AdminLogin from "../components/Admin/Auth/AdminLogin";
import AdminWrapper from "../components/Admin/AdminWrapper";
import Dashboard from "../components/Admin/Dashboard/Dashboard";
import SubmittedOffers from "../components/Admin/Dashboard/SubmittedOffers";
import ForgotPassword from "../components/Admin/Auth/ForgotPassword";
import ResetPassword from "../components/Admin/Auth/ResetPassword";
import AddNewAdmin from "../components/Admin/Dashboard/AddNewAdmin";
import ManageAdmin from "../components/Admin/Dashboard/ManageAdmin";
import AdminProfile from "../components/Admin/Dashboard/AdminProfile";
import ManageUsersQueries from "../components/Admin/Dashboard/ManageUsersQueries";
import Protected, { PrivateRoute } from "./Protected";
import UserDashboard from "../components/users/dashboard";
import Profile from "../components/users/dashboard/profile";
import UserResetPassword from "../components/users/dashboard/resetPassword";
import { getSessionData, removeSessionData } from "../apis/auth.atom";
import { useAppDispatch, useAppSelector } from "../store/stateHooks";
import { UserAuthActions } from "../store/appStateSlices/userAuthSlice";
import NoInternetPage from "../components/UI/noInternet";
import PageNotFound from "../components/UI/pageNotFound";
import OtpVerify from "../components/Admin/Auth/otpVerify";
import OfferDetails from "../components/users/dashboard/offerDetails";

function WebRoutes() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!getSessionData()) {
      const user = {
        auth: false,
      };
      dispatch(UserAuthActions.setUserAuth(user));
      removeSessionData();
    }
  }, []);

  const { user } = useAppSelector((state) => state.user);
  const isAuthenticated = user.auth;
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PageManager />}></Route>
        <Route exact path="*" element={<PageNotFound />} />
        <Route path="/no-internet" element={<NoInternetPage />} />
        <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
          <Route path="/dashboard" element={<UserDashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/reset-password" element={<UserResetPassword />} />
          <Route path="/offer-details/:offerId" element={<OfferDetails />} />
        </Route>
        {/* ==============| Admin Routes |================= */}
        <Route path="/admin-login" exact element={<AdminLogin />}></Route>
        <Route path="/otp-veryfy/:id" exact element={<OtpVerify />}></Route>
        <Route
          path="/forgot-password"
          exact
          element={<ForgotPassword />}
        ></Route>
        <Route
          path="/reset-password/:token"
          exact
          element={<ResetPassword />}
        ></Route>
        <Route
          path="/admin/profile"
          exact
          element={
            <Protected>
              <AdminWrapper component={<AdminProfile />} />
            </Protected>
          }
        ></Route>
        <Route
          path="/admin/dashboard"
          exact
          element={
            <Protected>
              <AdminWrapper component={<Dashboard />} />
            </Protected>
          }
        ></Route>
        <Route
          path="/admin/add-new-admin"
          exact
          element={
            <Protected>
              <AdminWrapper component={<AddNewAdmin />} />
            </Protected>
          }
        ></Route>
        <Route
          path="/admin/manage-admins"
          exact
          element={
            <Protected>
              <AdminWrapper component={<ManageAdmin />} />
            </Protected>
          }
        ></Route>
        <Route
          path="/admin/submitted-offers"
          exact
          element={
            <Protected>
              <AdminWrapper component={<SubmittedOffers />} />
            </Protected>
          }
        ></Route>
        <Route
          path="/admin/users/queries"
          exact
          element={
            <Protected>
              <AdminWrapper component={<ManageUsersQueries />} />
            </Protected>
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default WebRoutes;
