import { nanoid } from "nanoid";
import { navigationActions } from "../../store/appStateSlices/navigationSlice";
import Button from "./Button";
import WarrantyLevel from "./WarrantyLevel";
import { Offer as IOffer } from "../hooks/useAPI";
import { useAppDispatch, useAppSelector } from "../../store/stateHooks";
import classes from "./Offer.module.css";
import getWarrantiesIcons from "../helpers/getWarrantiesIcons";
import { offerActions } from "../../store/appStateSlices/selectedOffer";
import { useEffect, useState } from "react";
import DateFieldInsure from "./DateFieldInsure";
import lang from "../../utils/language/french"
import { ConfirmationModal, AuthModal } from "../modals";
import showToast from "../../utils/toast";
import { getSessionData } from "../../apis/auth.atom";
import { postAPI } from "../../apis/Services";
import { api } from "../../utils/storage";

interface Props {
  provider: IOffer["provider"];
  monthlyPrice: number;
  levels: (1 | 2 | 3 | 4)[];
  name?: string;
  code?: string;
  description?: string;
  label?: string;
  check?: Number;
}

function Offer(props: Props) {
  const dispatch = useAppDispatch();

  const warranties = getWarrantiesIcons();

  function confirmOffer(offer: Props) {
    dispatch(offerActions.setOffer(offer));
    dispatch(navigationActions.setActiveAppPage(4));
  }
  const [res, setRes] = useState<String>("");

  const [display, setDisplay] = useState<String>("none");
  const [padding, setPadding] = useState<String>("");
  const [timeSlot, setTimeSlot] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<any>('');

  const [showModal, setShowModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [confirmationCallback, setConfirmationCallback] = useState<(() => void) | null>(null);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };
  const showConfirmationModal = (callback: () => void) => {
    setConfirmationCallback(() => callback);
    setShowModal(true);
  };
  const subscriberData = useAppSelector((state) => state.userData);
  const { user } = useAppSelector((state) => state.userAuthCredential.userAuth);

  const postOfferData = async (offer: Props) => {
    try {
      let dateTime = { date: selectedDate, timeSlot: selectedItems[0] };
      const data = { data: subscriberData, offer: offer, dateTime: dateTime, callEmailPermissionStatus: isChecked }
      const response = await postAPI(api.confirmOffer, data)
      setPadding("9px 5px");
      window.scrollTo(0, 0);
      if (!response.error) {
        showToast(lang.offer.successALert, "success");
        setDisplay("block");
        setSelectedItems([]);
        setSelectedDate("");
      } else {
        showToast(response.message, "info");
        setPadding("0px");
        setDisplay("block");
      }
    } catch (err) {

      showToast(lang.offer.errorMessage, "error");
      setDisplay("block");
      window.scrollTo(0, 0);
    }

  }
  const confirmUsersOffer = (offer: Props) => {
    if (selectedItems.length > 0 && selectedDate !== "") {
      if(isChecked){
      if (getSessionData() === null) {
        setShowAuthModal(true)
      } else {
        showConfirmationModal(() => postOfferData(offer));
      }
      } else{
        showToast("Veuillez cocher la case des autorisations pour continuer.", "info");
      }
    } else {
      showToast(lang.offer.notSelectSlot, "info");
      setDisplay("block");
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    const slots = [
      "09h - 10h",
      "10h - 11h",
      "11h - 12h",
      "12h - 13h",
      "13h - 14h",
      "14h - 15h",
      "15h - 16h",
      "16h - 17h",
      "17h - 18h",
      "18h - 19h",
      "19h - 20h",
    ];

    setTimeSlot(slots);
  }, []);

  function selectTimeSlot(time_slot: string) {
    if (timeSlot.includes(time_slot)) {
      setSelectedItems(timeSlot.filter((id) => id === time_slot));
    }
    setDisplay("none");
    setRes("");
  }
  function handleDateFieldChange(value: string) {
    const newDate = value ? new Date(value) : undefined;
    setSelectedDate(newDate);
  }

  const handleConfirm = () => {
    if (confirmationCallback) {
      confirmationCallback();
      setShowModal(false);
      setShowAuthModal(false);
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    setShowAuthModal(false);
  };
  return (
    <>
      <ConfirmationModal
        show={showModal}
        title={lang.offer.confirmOffierModalTitle}
        message={lang.offer.confirmOfferModalBody}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <AuthModal
        show={showAuthModal}
        onCancel={handleCancel}
        setShowAuthModal={() => { postOfferData(props); setShowAuthModal(false); }}
      />
      {props.check === 0 ? null : (
        <div className="container mb-3 mt-2">
          <div className="row">
            <div className="col-md-12 p-0">
              <div className="card ">
                <div className="card-body">
                  <h5 className="card-title">
                    À propos {props.name || props.label || props.provider}(
                    {props.provider})
                  </h5>

                  <p style={{ fontSize: "14px" }}>
                    L'offre d'assurance avec le code {props.code}, intitulée{" "}
                    <b>"{props.name || props.label || props.provider}"</b>{" "}
                    proposée par <b>{props.provider}</b>, offre une couverture
                    pour divers avantages. Le prix mensuel de cette assurance
                    est de <b>{props.monthlyPrice}€</b>.
                    <br />
                    <br />
                    <b>{props.provider} </b> offre une couverture complète pour
                    les particuliers, garantissant protection et tranquillité
                    d'esprit. Avec ce plan d'assurance, vous pouvez profiter
                    d'une large gamme d'avantages adaptés à vos besoins. Que ce
                    soit pour les frais médicaux, l'hospitalisation ou d'autres
                    services de santé, {props.provider} vous couvre.
                    <br />
                    <br />
                    Le plan d'assurance propose un prix mensuel compétitif de
                    <b>{props.monthlyPrice}€</b>, ce qui en fait une option
                    abordable pour les particuliers recherchant une couverture
                    fiable. {props.provider}, un fournisseur d'assurance réputé,
                    veille à ce que vous receviez le soutien et l'assistance
                    dont vous avez besoin chaque fois que vous en avez besoin.
                    <br />
                    <br />
                    Avec <b>{props.name || props.label || props.provider}</b>,
                    vous pouvez faire face en toute confiance à toutes les
                    circonstances imprévues, sachant que vous bénéficiez d'une
                    couverture d'assurance fiable. Ce plan vise à vous offrir
                    une sécurité financière et à vous aider à maintenir votre
                    bien-être général.
                  </p>

                  <h2 className="card-title mb-0 pb-1">
                    Sélectionnez votre créneau horaire approprié pour un rappel
                    gratuit
                  </h2>
                  <p style={{ fontSize: "14px", margin: "5px" }}>
                    Choisissez votre créneau horaire approprié pour un rappel
                    gratuit
                  </p>

                  <div className="mb-3 mt-3" style={{ width: '30px' }}>
                    <DateFieldInsure
                      state={selectedDate}
                      getState={handleDateFieldChange}
                    />
                  </div>
                  {timeSlot.map((data, index) => (
                    <span
                      key={index}
                      onClick={() => selectTimeSlot(data)}
                      // className="time_slot"
                      className={
                        selectedItems.includes(data)
                          ? "time_slot selected"
                          : "time_slot"
                      }
                    >
                      {data}
                    </span>
                  ))}
                  <div className="form-check mt-4">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="partnerContact"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                      <label className="form-check-label" htmlFor="partnerContact">
                        Accepteriez-vous d'être appelé et envoyé par email par l'un de nos partenaires offrant le meilleur rapport qualité/prix par rapport à vos besoins ?
                      </label>
                    </div>
                    <div className="mt-2">
                      <h4 className="card-title pb-1 mb-0">
                        Appeler l'assureur 01 84 20 00 60
                      </h4>
                      <p style={{ fontSize: "14px" }} className="p-0">
                        Posez toutes vos questions, les assureurs sont là pour
                        vous aider. Appelez du lundi au vendredi de 9h00 à 20h00.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      )}

          <div className={classes.container + " mb-4"}>
            <div className={classes.provider}>
              <img
                className={classes.logo}
                src={`${process.env.PUBLIC_URL}/assets/img/${props.provider}-Logo.png`}
                alt={`${props.provider} Logo`}
              />
              <h4
                className={classes.label}
                style={{ fontSize: "15px", fontWeight: "700" }}
              >
                {props.name || props.label || props.provider}
              </h4>
              {props.code && <p className={classes.code}>{props.code}</p>}
            </div>
            <div className={classes.warranties}>
              {warranties.map((warranty, i) => (
                <WarrantyLevel
                  key={nanoid(6)}
                  icon={warranty.icon}
                  level={props.levels[i]}
                />
              ))}
            </div>
            <div className={classes.more}>
              <div className={classes.price}>
                <div className={classes["monthly-price"]}>
                  <span>{props.monthlyPrice} €</span>
                  <span> /mois</span>
                </div>
                <div className={classes["yearly-price"]}>
                  <p>Budget estimé: {Math.round(props.monthlyPrice * 12)} €/ans</p>
                </div>
              </div>
              {props.check === 0 ? (
                <Button onClick={() => confirmOffer(props)}>Savoir Plus</Button>
              ) : (
                <Button onClick={() => confirmUsersOffer(props)}>Confirmer</Button>
              )}
            </div>
          </div>
        </>
      );
}

      export default Offer;
