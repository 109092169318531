import { nanoid } from "nanoid";

import OffersHeading from "./OffersHeading";
import Offer from "./Offer";

import classes from "./OffersBox.module.css";
import { Offer as IOffer } from "../hooks/useAPI";

interface Props {
  offers: IOffer[];
}

function OffersBox(props: Props) {
  function renderOffers() {
    return props.offers.map((offer) => {
      const levels = Object.values(offer.warranties!).map((warranty) => {
        return warranty.level;
      });

      return (
        <Offer
          key={nanoid(6)}
          provider={offer.provider}
          monthlyPrice={offer.monthlyPrice}
          levels={levels}
          name={offer.name}
          label={offer.label}
          code={offer.code}
          description={offer.description}
          check={0}
        />
      );
    });
  }

  return (
    <div className={classes.container}>
      <OffersHeading offersNumber={props.offers.length}>Offres</OffersHeading>
      <div className={classes["offers-container"]}>{renderOffers()}</div>
    </div>
  );
}

export default OffersBox;
