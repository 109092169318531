import { Link } from "react-router-dom";
import { useState } from "react";
import NavbarBox from "./NavbarBox";
import classes from "./FormNavbar.module.css";
import ProgressBar from "./ProgressBar";
import { useAppSelector, useAppDispatch } from "../../store/stateHooks";
import { AuthModal } from "../modals";
import { navigationActions } from "../../store/appStateSlices/navigationSlice";
function FormNavbar() {
  const { user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const isAuthenticated = user.auth;
  const [showAuthModal, setShowAuthModal] = useState(false);
  const handleCancel = () => {
    setShowAuthModal(false);
  };

   const { activeAppPage, activeFormPage } = useAppSelector(
    (state) => state.appState.navigation
  );

  function restartPage() {
    dispatch(navigationActions.setActiveFormPage(1));
  }
  return (
    <NavbarBox>
      <AuthModal
        show={showAuthModal}
        onCancel={handleCancel}
        setShowAuthModal={handleCancel}
        login={showAuthModal}
      />
      <ProgressBar />
      <div style={{ display: 'flex', gap: '15px' }}>
        {activeFormPage != 1 && <Link to="/" className={classes.restart} onClick={restartPage}>
          Redémarrer
        </Link>}
        {
          isAuthenticated ? (<Link to="/dashboard" className={classes.restart}>
            Tableau de bord
          </Link>) : (<div onClick={() => setShowAuthModal(true)} className={classes.restart}>
            se connecter
          </div>)
        }
      </div>
    </NavbarBox>
  );
}

export default FormNavbar;
