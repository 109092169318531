import React, { useState } from "react";

const InputField = ({
  label,
  name,
  type,
  register,
  errors,
  validation,
  placeholder,
  value,
  onChange,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // Determine the input type
  const inputType = type === "password" && showPassword ? "text" : type;

  return (
    <div className="mb-3 position-relative">
      <strong htmlFor={name} className="form-label fw-normal" style={{color: '#000'}}>
        {label}
      </strong>
      <div className="input-group">
        <input
          type={inputType}
          value={value}
          onChange={onChange}
          id={name}
          {...register(name, validation)}
          placeholder={placeholder}
          style={{
            width: "100%",
            padding: "10px",
            border: errors[name] ? "1px solid red" : "1px solid #ced4da",
            borderRadius: "4px",
            outline: "none",
            boxShadow: errors[name] ? "0 0 5px red" : "none",
            transition: "border-color 0.3s ease, box-shadow 0.3s ease",
          }}
        />
        {type === "password" && (
          <span
            onClick={togglePasswordVisibility}
            className="position-absolute end-0 top-50 translate-middle-y pe-2"
            style={{ cursor: "pointer" }}
          >
            {showPassword ? (
              <i className="bi bi-eye-slash-fill"></i> // Hide icon
            ) : (
              <i className="bi bi-eye-fill"></i> // Show icon
            )}
          </span>
        )}
      </div>
      {errors[name] && (
        <div className="invalid-feedback" style={{ color: "red" }}>
          {errors[name].message}
        </div>
      )}
    </div>
  );
};

export default InputField;
