import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { useAppSelector } from "../../../store/stateHooks";
import lang from "../../../utils/language/french";
function ManageAdmin() {
  const [data, setData] = useState([]);
  const [deleteId, setDeleteId] = useState(0);
  const SERVER_URL = process.env.REACT_APP_API_SERVER;
  const { auth } = useAppSelector((state) => state.authCredential.auth);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${auth.data.token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${SERVER_URL}/api/v1/admin/get/all/${auth.data.admin_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => console.log("error", error));
  }, [deleteId]);
  // ==========================|Change Password|=====================
  function deleteAdminRecord() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${auth.data.token}`);
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${SERVER_URL}/api/v1/admin/delete/${auth.data.admin_id}/${deleteId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setDeleteId(0);
        document.getElementById("closeModal").click();
      })
      .catch((error) => console.log("error", error));
  }
  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>{lang.manageAdmin.manage}</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/admin/dashboard">{lang.common.home}</Link>
              </li>
              <li className="breadcrumb-item active">
                {lang.manageAdmin.manage}
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="card-title">{lang.manageAdmin.manage}</h5>
                  <Link to="/admin/add-new-admin">
                    <button className="btn btn-primary">
                      {lang.manageAdmin.addNewAdmin}
                    </button>
                  </Link>
                </div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">{lang.common.name}</th>
                      <th scope="col">{lang.common.email}</th>
                      <th scope="col">{lang.manageAdmin.role}</th>
                      <th scope="col">{lang.admin.offerHeader.phoneNumber}</th>
                      <th scope="col">{lang.query.Status}</th>
                      <th scope="col">{lang.query.createdDate}</th>
                      <th scope="col">{lang.query.Action}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length < 1 ? (
                      <tr>
                        <td colSpan={7} className="text-center">
                          {lang.manageAdmin.dataNotExist}
                        </td>
                      </tr>
                    ) : (
                      data.map((admin, key) => {
                        return (
                          <tr>
                            <th>{key + 1}</th>
                            <td>{admin.name} </td>
                            <td>{admin.email}</td>
                            <td>
                              {admin.role === "super-admin" ? (
                                <span className="badge rounded-pill bg-success">
                                  {`Super ${lang.admin.admin}`}
                                </span>
                              ) : admin.role === "admin" ? (
                                <span className="badge rounded-pill bg-primary">
                                  {lang.admin.admin}
                                </span>
                              ) : (
                                <span className="badge rounded-pill bg-info text-dark">
                                  {lang.admin.subAdmin}
                                </span>
                              )}
                            </td>
                            <td>{admin.phone}</td>
                            <td>
                              {admin.status === 1 ? (
                                <span className="badge rounded-pill bg-success">
                                  {lang.common.verify}
                                </span>
                              ) : (
                                <span className="badge rounded-pill bg-danger">
                                  {lang.common.notVerify}
                                </span>
                              )}
                            </td>
                            <td>
                              {format(
                                new Date(admin.created_at),
                                "dd MMM yyyy",
                                { locale: fr }
                              )}
                            </td>
                            <td>
                              <button
                                onClick={() => setDeleteId(admin._id)}
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#deleteConfirmation"
                                className="btn btn-danger"
                              >
                                <i className="bi bi-trash"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Admin Delete Confirmation Modal --> */}
        <div class="modal fade" id="deleteConfirmation">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body p-4 mt-2">
                <p style={{ fontWeight: "400", fontSize: "14px" }}>
                  Are you sure you want to delete this record?
                </p>
                <div className="mt-2">
                  <button
                    type="button"
                    class="btn btn-primary mx-1"
                    onClick={() => deleteAdminRecord()}
                  >
                    Yes! Delete
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger mx-2"
                    data-bs-dismiss="modal"
                    id="closeModal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ManageAdmin;
