import { useForm } from "react-hook-form";
import InputField from "../components/inputField";
import { api } from "../../../utils/storage";
import { putAPI } from "../../../apis/Services";
import showToast from "../../../utils/toast";

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const body = {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      };
      const res = await putAPI(api.resetPassword, body);
      showToast(res.message, "success");
    } catch (err) {
      const errorMessage =
        err.response?.data?.msg ||
        "Quelque chose s'est mal passé, veuillez réessayer.";
      showToast(errorMessage, "error");
    }
  };

  const newPassword = watch("newPassword");

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div
        className="card p-4 shadow-lg"
        style={{ maxWidth: "500px", width: "100%" }}
      >
        <h3 className="fw-bold mb-4 text-center text-primary">
          Réinitialiser le mot de passe
        </h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputField
            key="oldPassword"
            label="Ancien mot de passe"
            name="oldPassword"
            type="password"
            placeholder="Entrez votre ancien mot de passe"
            register={register}
            errors={errors}
            validation={{
              required: "L'ancien mot de passe est requis",
            }}
          />

          <InputField
            key="newPassword"
            label="Nouveau mot de passe"
            name="newPassword"
            type="password"
            placeholder="Entrez votre nouveau mot de passe"
            register={register}
            errors={errors}
            validation={{
              required: "Un nouveau mot de passe est requis",
              minLength: {
                value: 8,
                message: "Le mot de passe doit comporter au moins 8 caractères",
              },
            }}
          />

          <InputField
            key="confirmPassword"
            label="Confirmer le nouveau mot de passe"
            name="confirmPassword"
            type="password"
            placeholder="Confirmer le nouveau mot de passe"
            register={register}
            errors={errors}
            validation={{
              required: "Veuillez confirmer votre mot de passe",
              validate: (value) =>
                value === newPassword ||
                "Les mots de passe ne correspondent pas",
            }}
          />

          <button type="submit" className="btn btn-primary w-100 mt-3">
            Réinitialiser le mot de passe
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
