import SectionHeading from "../../UI/SectionHeading";

import Levels from "./questions/Levels";
import FormBox from "../../UI/FormBox";
import Button from "../../UI/Button";
import { navigationActions } from "../../../store/appStateSlices/navigationSlice";
import { useAppDispatch } from "../../../store/stateHooks";

function SelectWarranties() {
  const dispatch = useAppDispatch();

  function nextPageHandler() {
    dispatch(navigationActions.setActiveFormPage(3));
  }

  return (
    <FormBox>
      <SectionHeading>Besoins</SectionHeading>
      <Levels />
      <Button onClick={nextPageHandler}>Continuer</Button>
    </FormBox>
  );
}

export default SelectWarranties;
