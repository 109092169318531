import baseAxios from "./baseAxios";
import { removeSessionData } from "./auth.atom";

export const postAPI = async (url, body) => {
  return baseAxios.post(url, body).then(
    (res) => {
      return res;
    },
    (err) => Promise.reject(err)
  );
};
export const getAPI = async (url) => {
  try {
    const response = await baseAxios.get(url);
    return response; // Assuming you want to return the data part of the response
  } catch (err) {
    // Handle network errors or if there is no internet connection
    if (!err.response) {
      return {
        status: null,
        message:
          "Problème de réseau détecté. Vérifiez votre connexion Internet.",
      };
    }
    // Unauthorized Error
    if (
      err.response.status === 401 &&
      err.response.statusText === "Unauthorized"
    ) {
      removeSessionData();
      return {
        status: 401,
        message: "La session a expiré, veuillez vous reconnecter",
      };
    }
    // Option 1: Rethrow the error for the caller to handle
    else return err.response.data;
  }
};

export const putAPI = async (url, body) => {
  return baseAxios.put(url, body).then(
    (res) => {
      return res;
    },
    (err) => Promise.reject(err)
  );
};
